import React, { useState } from "react"
import { Link } from "./utils"
import useForm, { useFormContext, Controller } from "react-hook-form"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from "date-fns/locale/ja"
import dateFormat from "dateformat"

registerLocale("ja", ja)

type InputData = {
    name: string
    address: string
    telephone: string
    email: string
    Sdate: string
    start: string
    Edate: string
    end: string
    keep: string
    message: string
    // register: any
    // control:any
}
type ButtonProps = {
    title: string
    to?: string
    type?: any
    label?: string
    disabled?: boolean
    iconLeft?: JSX.Element
    iconRight?: JSX.Element
    // onClick?: any
}
const Button: React.FC<ButtonProps> = props => {
    const { title, to, type, label, disabled } = props

    let innerComponents = (
        <React.Fragment>
            {props.iconLeft && (
                <span className="icon icon-left">{props.iconLeft}</span>
            )}
            <span>{props.title}</span>
            {props.iconRight && (
                <span className="icon icon-right">{props.iconRight}</span>
            )}
        </React.Fragment>
    )

    if (type) {
        const b = type.split(",")
        const t = b[1] ? b[1] : "button"
        const dis = disabled === undefined ? false : disabled
        if (b[0] === "button") {
            return (
                <button
                    type={t}
                    disabled={dis}
                    className={`rounded-lg btn btn-primary${
                        dis ? " disabled" : ""
                    }`}
                    // onClick={onClick}
                >
                    {innerComponents}
                </button>
            )
        }
    }
    return (
        <Link to={to} className="btn btn-primary " title={label || title}>
            {innerComponents}
        </Link>
    )
}

const TextInput = ({ label, type = "text", name, footer }) => {
    const { register, setValue } = useFormContext<InputData>()

    const [focused, changeFocused] = useState(false)
    const trans = e => {
        const str = e.target.value
        setValue(
            name,
            str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
                return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
            })
        )
    }
    let elem = (
        <input
            type={type}
            name={name}
            className="input w-full text-color-default resize-none px-4 py-2"
            onFocus={() => changeFocused(true)}
            onBlur={trans}
            aria-label={name}
            ref={register}
        />
    )

    // if (type === "number") {
    //     elem = (
    //         <input
    //             className="input w-full text-color-default resize-none px-4 py-2"
    //             name={name}
    //             max={11}
    //             onFocus={() => changeFocused(true)}
    //             onBlur={() => changeFocused(false)}
    //             aria-label={name}
    //             ref={register}
    //         />
    //     )
    // }

    return (
        <div className="transition-all duration-300 py-3 lg:p-4 pb-6">
            <p className="text-color-4 text-lg">{label}</p>
            {elem}
            <div className="underline p-2px" />
            {footer && <div className="text-error">{footer}</div>}
        </div>
    )
}

// const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
//     return <input {...props} defaultValue={defaultValue} ref={inputRef} />
// }
const DatePicker = ({ label, type = "date", name, footer, min }) => {
    const { register, setValue, watch } = useFormContext<InputData>()

    const { control } = useFormContext<InputData>()
    const [date, setDate] = useState()
    const [focused, changeFocused] = useState(false)
    // const d = new Date()
    const mindate = new Date(min)
    const watchvalue = watch(name)
    const handleChange = (e: any) => {
        // const Date = methods.getValues(name)
        const FormatDate = dateFormat(e, "isoDate")
        setValue(name, FormatDate)
        // console.log(FormatDate)
    }
    let elem = (
        // <Flatpickr
        //     render={({ defaultValue, value, ...props }, ref) => {
        //         return (
        //             <CustomInput
        //                 defaultValue={defaultValue}
        //                 inputRef={register}
        //                 value={value}
        //             />
        //         )
        //     }}
        // />
        <Controller
            control={control}
            name={name}
            render={props => (
                <ReactDatePicker
                    locale="ja"
                    className={`input w-full text-color-default resize-none px-4 py-2 `}
                    // placeholderText="Select date"
                    onChange={handleChange}
                    // selected={handleChange}
                    value={watchvalue}
                    dateFormat="yyyy-MM-dd"
                    minDate={mindate}
                />
            )}
        />
    )

    if (type === "text") {
        elem = (
            <input
                name={name}
                className="input w-full text-color-default resize-none px-4 py-2"
                onFocus={() => changeFocused(true)}
                onBlur={() => changeFocused(false)}
                aria-label={name}
                // ref={register}

                // min={new Date()}
            />
        )
    }

    return (
        <div className="transition-all duration-300 py-3 lg:p-4 pb-6">
            <p className="text-color-4 text-lg">{label}</p>
            {elem}
            <div className="underline p-2px" />
            {footer && <div className="text-error">{footer}</div>}
        </div>
    )
}
const Select = ({ label, type = "radio", name, footer, value }) => {
    const { register } = useFormContext<InputData>()

    const [focused, changeFocused] = useState(false)
    let elem = (
        <div className="row pt20">
            <div className="col-xs-12">
                <div className="flex flex-col sm:flex-row form-group radio-wrap">
                    {value.map(d => (
                        <label className="label-radio py-3 sm:py-0" key={d.id}>
                            <input
                                type={type}
                                name={name}
                                value={d.value}
                                onFocus={() => changeFocused(true)}
                                onBlur={() => changeFocused(false)}
                                aria-label={name}
                                ref={register}
                                className=""
                            />
                            <span className="lever text-color-4">
                                {d.value}
                            </span>
                        </label>
                    ))}
                </div>
            </div>
        </div>
    )

    return (
        <div className="transition-all duration-300 py-3 lg:p-4 pb-6">
            <p className="text-color-4 text-lg">{label}</p>
            {elem}
            <div className="underline p-2px" />
            {footer && <div className="text-error">{footer}</div>}
        </div>
    )
}
const Check = ({ label, type = "checkbox", name, footer, value }) => {
    const { register, setValue } = useFormContext<InputData>()

    const [focused, changeFocused] = useState(false)
    let elem = (
        <div className="flex flex-col ">
            <div className="flex flex-col">
                {value.map(d => (
                    <label
                        className="flex justify-start items-start"
                        key={d.id}
                    >
                        <div className="bg-white border-2 rounded border-gray w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 my-1 focus-within:border-blue-500">
                            <input
                                className="opacity-0 absolute"
                                type={type}
                                name={name}
                                value={d.value}
                                onFocus={() => changeFocused(true)}
                                onBlur={() => changeFocused(false)}
                                // onChange={() => setValue(`${name}`, value)}
                                aria-label={name}
                                ref={register}
                            />
                            <svg
                                className="fill-current hidden w-4 h-4 text-green pointer-events-none"
                                viewBox="0 0 20 20"
                            >
                                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                            </svg>
                        </div>
                        <div className="select-none my-1"> {d.value}</div>
                    </label>
                ))}
            </div>
        </div>
    )
    return (
        <div className="transition-all duration-300 py-3 lg:p-4 pb-6">
            <p className="text-color-4 text-lg">{label}</p>
            {elem}
            <div className="underline p-2px" />
            {footer && <div className="text-error">{footer}</div>}
        </div>
    )
}
export { Button, TextInput, DatePicker, Select, Check }
